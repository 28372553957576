import { template as template_57b6e432156d4f4384e621e37008edd5 } from "@ember/template-compiler";
const WelcomeHeader = template_57b6e432156d4f4384e621e37008edd5(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
