import { template as template_912b8292c6e547c6b0146e383dc64360 } from "@ember/template-compiler";
const FKControlMenuContainer = template_912b8292c6e547c6b0146e383dc64360(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
