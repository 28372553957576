import { template as template_cfdafa4220424a97b2a20dbad29ccf9d } from "@ember/template-compiler";
const FKLabel = template_cfdafa4220424a97b2a20dbad29ccf9d(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
